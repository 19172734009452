/*--------------------------------------------------
Developed by Renato Hoxha

--------------------------------------------------*/
"use strict";

(function ($) {
  let isSafari =
      /constructor/i.test(window.HTMLElement) ||
      (function (p) {
        return p.toString() === "[object SafariRemoteNotification]";
      })(!window["safari"] || safari.pushNotification),
    isIE11 = !!navigator.userAgent.match(/Trident.*rv[ :]*11\./),
    isMobile = false;

  function oldBrowsers() {
    if (isIE11 === true) html.classList.add("msie");
    if (isSafari === true) html.classList.add("safari");
  }

  // === Check if is Mobile !!! by UserAgent not by breakpoint
  window.mobileAndTabletCheck = function () {
    (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      ) {
        isMobile = true;
      }
    })(navigator.userAgent || navigator.vendor || window.opera);
  };
  mobileAndTabletCheck();


  function EcosystemSlider() {

    $('.ecosystem-slider-js').slick({
      autoplay:true,
      autoplaySpeed:3000,
      arrows:false,
      fade: true
    });

  }

  function SliderOnMobile(){
    var width = $(window).width();
    if (width <= 767) {
      $('.slider-on-mobile').slick({
        arrows: false
      })
    } else {
      
    }
  }

  function CardsSlider(){
    $('.cards-slider').slick({
      dots: false,
      slidesToShow:4,
      infinite:false,
      variableWidth: true,
      responsive: [
                    {
                        breakpoint: 1620, // tablet breakpoint
                        settings: {
                            slidesToShow: 3
                        }
                    },
                    {
                        breakpoint: 1280, // tablet breakpoint
                        settings: {
                            slidesToShow: 2
                        }
                    },
                    {
                        breakpoint: 980, // tablet breakpoint
                        settings: {
                            slidesToShow: 2
                        }
                    },
                    {
                        breakpoint: 480, // mobile breakpoint
                        settings: {
                            slidesToShow: 1
                        }
                    }
                ]
    });

    $('.timeline-carousel').slick({
      dots: false,
      slidesToShow: 3,
      infinite:false,
      responsive: [
                    {
                        breakpoint: 1024, // tablet breakpoint
                        settings: {
                            slidesToShow: 2
                        }
                    },
                    {
                        breakpoint: 480, // mobile breakpoint
                        settings: {
                            slidesToShow: 1
                        }
                    }
                ]
    });
  }

  function TabSection(){
    $('.tabs-links').on('click', '.btn', function(event) {
      event.preventDefault();
      var target = $(this).data('target');
      $('.tabs-links .btn.active').removeClass('active');
      $(this).addClass('active');
      $('.tabs-content').fadeOut('400', function() {
        $('.tabs').find('#'+target).fadeIn('400');
      });
    });
  }

  function GallerySlider(){
     $('.gallery').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      fade: false,
      adaptiveHeight: true,
      infinite:false,
      asNavFor: '.gallery-nav-thumbnails',
     });

     $('.gallery-nav-thumbnails').slick({
      slidesToShow: 5,
      slidesToScroll: 1,
      asNavFor: '.gallery',
      dots: false,
      focusOnSelect: true
     });

     // Remove active class from all thumbnail slides
     $('.gallery-nav-thumbnails .slick-slide').removeClass('slick-active');

     // Set active class to first thumbnail slides
     $('.gallery-nav-thumbnails .slick-slide').eq(0).addClass('slick-active');

     // On before slide change match active thumbnail to current slide
     $('.gallery').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
      var mySlideNumber = nextSlide;
      $('.gallery-nav-thumbnails .slick-slide').removeClass('slick-active');
      $('.gallery-nav-thumbnails .slick-slide').eq(mySlideNumber).addClass('slick-active');
    });
  }

  function MenuToggle(){
    $('body').on('click', '.menu-toggle', function(event) {
      event.preventDefault();
      $('body').toggleClass('nav-open');
      $(this).toggleClass('is-active');
    });
  }

  function ParallaxEffect(){
      /** change value here to adjust parallax level */
      var parallax = -0.3;

      var $bg_images = $(".parallax_effect");
      var offset_tops = [];
      $bg_images.each(function(i, el) {
        offset_tops.push($(el).offset().top);
      });

      function ParallaxFunction(){
        var dy = $(this).scrollTop();
        $bg_images.each(function(i, el) {
          var ot = offset_tops[i];
          $(el).css("background-position", "50% " + (dy - ot) * parallax + "px");
        });
      }

      ParallaxFunction();

      $(window).scroll(function() {
        ParallaxFunction();
      });

  }

  function AccordionsItem(){
    $('.accordion-item').on('click', '.accordion-title', function(e) {

      e.preventDefault();

      if(!$(this).hasClass('open')) {
        $('.accordion-title.open').removeClass('open');
        $('.accordion-content.open').slideUp().removeClass('open');

        $(this).addClass('open');
        $(this).next().addClass('open').slideDown();
      }else{
        $('.accordion-title.open').removeClass('open');
        $('.accordion-content.open').slideUp().removeClass('open');
      }

    });
  }
  // === DOC READY ===
  $(function () {
    EcosystemSlider();
    new WOW().init();
    // const scroller = new LocomotiveScroll({
    //   el: document.querySelector('[data-scroll-container]'),
    //   smooth: true
    // });
    

  });
    
  function initMap() {
      var Markers = {};
      var infowindow;
      var i;

      const icon = {
        path:
          "M 25, 50 a 25,25 0 1,1 50,0 a 25,25 0 1,1 -50,0",
        fillColor: "black",
        fillOpacity: 1,
        strokeWeight: 0,
        rotation: 0,
        scale: 0.4,
        anchor: new google.maps.Point(15, 30),
        // labelOrigin: new google.maps.Point(20,20)
      };

      function initialize() {
        var mapOptions = {
            // How zoomed in you want the map to start at (always required)
            zoom: parseInt(zoomValue),

            // The latitude and longitude to center the map (always required)
            center: new google.maps.LatLng(latCenter, lngCenter), // Istanbol

            zoomControl: true,
            zoomControlOptions: {
              position: google.maps.ControlPosition.RIGHT_TOP,
            },
            fullscreenControl: false,
            streetViewControl: false,

            // How you would like to style the map. 
            // This is where you would paste any style found on Snazzy Maps.
            styles: [{"stylers":[{"visibility":"off"}]},{"featureType":"landscape","elementType":"geometry","stylers":[{"visibility":"on"},{"hue":"#ffffff"},{"saturation":-100},{"lightness":100}]},{"featureType":"water","stylers":[{"visibility":"on"},{"lightness":-35},{"saturation":-100}]},{"featureType":"administrative.province","elementType":"geometry","stylers":[{"visibility":"on"}]},{"featureType":"administrative.country","elementType":"geometry","stylers":[{"visibility":"on"}]},{"featureType":"water","elementType":"labels","stylers":[{"visibility":"off"}]},{"featureType":"road.local","elementType":"geometry.fill","stylers":[{"visibility":"on"},{"color":"#000000"},{"lightness":90}]}]
        };

        // We are using a div with id="map" seen below in the <body>
        var mapElement = document.getElementById('map');

        // Create the Google Map using our element and options defined above
        var map = new google.maps.Map(mapElement, mapOptions);

        infowindow = new google.maps.InfoWindow();

        for(i=0; i<locations.length; i++) {
          var position = new google.maps.LatLng(locations[i][1], locations[i][2]);
          var marker = new google.maps.Marker({
            position: position,
            map: map,
            icon: base_url + '/wp-content/uploads/2023/03/map_marker.svg',
          });

          google.maps.event.addListener(marker, 'click', (function(marker, i) {
            return function() {
              infowindow.setContent(locations[i][3]);
              infowindow.setOptions({maxWidth: 313});
              infowindow.open(map, marker);
              marker.setIcon(base_url + '/wp-content/uploads/2023/03/map_marker.svg');
            }
          })(marker, i));
          Markers[locations[i][4]] = marker;
        }

      }

      google.maps.event.addDomListener(window, 'load', initialize);

  }

  function swipeLogoSrc(){
    var windowWidth = $( window ).width();

    $('.custom-logo').each(function(){
      var black_logo = $('.custom-logo').data('black');
      var white_logo = $(this).data('white');
      if (windowWidth >= 1024) {
          $(".mobile-white-logo .custom-logo").attr("src",black_logo);
      }else{
          $(".mobile-white-logo .custom-logo").attr("src",white_logo);
      }
    });
  }

  function inputLength(){
    $("#full_name").keyup(function() {
     if($(this).val().length >14) {
        $('.wpcf7-form-control-wrap.full-name, .wpcf7-form-control-wrap.full-name input').css({
          display: 'block',
          maxWidth: '100%',
          width : '100%'
        });
       }else{
        $('.wpcf7-form-control-wrap.full-name, .wpcf7-form-control-wrap.full-name input').css({
          display: 'inline-block',
          maxWidth: '270px',
          width : '100%'
        });
       }
     });

    $("#comp_name").keyup(function() {
     if($(this).val().length >14) {
        $('.wpcf7-form-control-wrap.comp-name, .wpcf7-form-control-wrap.comp-name input').css({
          display: 'block',
          maxWidth: '100%',
          width : '100%'
        });
       }else{
        $('.wpcf7-form-control-wrap.comp-name, .wpcf7-form-control-wrap.comp-name input').css({
          display: 'inline-block',
          maxWidth: '270px',
          width : '100%'
        });
       }
     });

    $("#your_email").keyup(function() {
     if($(this).val().length >14) {
        $('.wpcf7-form-control-wrap.your-email, .wpcf7-form-control-wrap.your-email input').css({
          display: 'block',
          maxWidth: '100%',
          width : '100%'
        });
       }else{
        $('.wpcf7-form-control-wrap.your-email, .wpcf7-form-control-wrap.your-email input').css({
          display: 'inline-block',
          maxWidth: '270px',
          width : '100%'
        });
       }
     });

    
  }

  function initParallax(){

    var windowWidth = $( window ).width();
    var image_src;

    $('.parallax-window').each(function(){
      var $this = $(this);
      var desktop_image = $this.data('image_desktop'),
       mobile_image = $this.data('image_mobile');
      if (windowWidth >= 1024) {
          image_src = desktop_image;
      }else{
        if (mobile_image.length > 0) {
          image_src = mobile_image;
        }else{
          image_src = desktop_image;
        }
      }
    });
    $('.parallax-window').parallax({
      imageSrc: image_src
    });
  }

  $(document).ready(function () {
    

    TabSection();
    GallerySlider();
    MenuToggle();
    SliderOnMobile();
    AccordionsItem();
    CardsSlider();
    swipeLogoSrc();
    if ($( window ).width() > 1024) {
      inputLength();
    }
    initParallax();

    var emailSubmited = localStorage['emailSubmited'];
    if (emailSubmited == 'true') {
        $('.disable-content, .subcribe-form').hide();

    }
    if ($( window ).width() < 1025) {
      $(".style-with-image .h4").fancybox();
    }

  });

  $( window ).on('resize', function() {
    swipeLogoSrc();
    initParallax();
  });


  window.mapsCallback = function () {
    if($('#map').length){
      initMap();
    };
  };
  

})(window.jQuery);